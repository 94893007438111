import styles from '@styles/components/Footer.module.scss';
import { AppContext } from '@utils/context/appContext';
import React, { useContext } from 'react';
import ModalSignInSignUp from '@components/modals/ModalSignInSignUp';
import useOpenModalLogin from '@hooks/auth/login/useOpenModalLogin';
import { useRouter } from 'next/router';
type Props = {
  wrapperStyle?: string,
  titleStyle?: string,
  textStyle?: string,
  isLanguage?: Boolean,
  title?: string,
  submenus?: {
    title?: string,
  }[],
};

export default function FooterNavigationColumn(props: Props) {
  const { wrapperStyle, title, submenus } = props;
  const router = useRouter();
  const { isLogin } = useContext(AppContext);
  const { gotoLogin, showModal, setShowModal } = useOpenModalLogin();
  return (
    <div className={`flex flex-1 flex-col ${wrapperStyle}`}>
      <div className={styles.titleFooter}>{title}</div>
      {submenus?.map((item, index) => {
        const { href = '#', mustLogin } = item;

        const finalHref = !mustLogin || (isLogin && mustLogin) ? href : '#';

        return (
          <div className="flex flex-row cursor-pointer" key={index}>
            <div
              onClick={async () => {
                if (!isLogin && mustLogin) {
                  gotoLogin();
                } else {
                  await router.push(finalHref);
                }
              }}
              key={index}>
              <div className={`mt-12px ${styles.subTitleFooter}`}>
                {item.title}
              </div>
            </div>
          </div>
        );
      })}
      {showModal && (
        <ModalSignInSignUp
          open={showModal}
          onClose={() => setShowModal(false)}
          configs={{ isSignIn: true }}
        />
      )}
    </div>
  );
}
